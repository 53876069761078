import Api from '@/services/Index';

// PARAMETER
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/parameters', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/parameter-items/' + id);
}

// PARAMETER ITEMS
const getAllItems = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/parameter-items', config);
}

const getItems = async (code, config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/parameter/' + code + '/items', config);
}

const getItem = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/parameter-items/' + id);
}

export default {
    getAll,
    get,

    getAllItems,
    getItems,
    getItem,
}
